/*!

=========================================================
* Argon Dashboard PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {useContext} from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import { wrapUserConsumer } from "../components/user-context.js" 

function Contact({ user }) {
    return (
      <>
        <Container fluid className="mt--6 col-sm-12 col-md-6 offset-md-3">
          <Row>
            <Col>
              <Card body>
                <p>
                  Thank you for using Policy Wizard. If you need support building your policies, please contact the Imagine Institute by email at mentors@imaginewa.org
                </p>
                <p>
                  Gracias por usar Policy Wizard. Si necesita apoyo para construir sus políticas, comuníquese con el Instituto Imagine por correo electrónico al mentors@imaginewa.org
                </p>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
}

export default wrapUserConsumer(Contact);

